import {Injectable, OnDestroy} from "@angular/core";
import {UserRoleConst, UserRole} from "@core/constants/user-roles.const";
import {AuthenticationParams, ResetPassword, UserRemindLogin} from "@helper/types/authentication";
import {Observable, ReplaySubject} from "rxjs";
import {map, take, tap} from "rxjs/operators";
import {BackendService} from "./backend.service";
import {Base64} from "js-base64";
import {NotificationService} from "./notification.service";
import {environment} from "src/environments/environment";

@Injectable()
export class AuthorizationService implements OnDestroy {
	public env = environment;
	private tokenKey = "dom.token";
	private superKey = "dom.super";
	private roleKey = "dom.role";
	private terminalIdKey = "dom.terminalId";
	private super: boolean;
	private activRole: number;
	private activeToken?: string;
	private storedTerminalId: string;
	private authorized$ = new ReplaySubject<boolean>();

	private readonly roles: UserRole[] = [
		UserRoleConst.ADMIN_ADMIN,
		UserRoleConst.ADMIN_SUPERADMIN,
		UserRoleConst.CABINET_SUPERUSER,
		UserRoleConst.CABINET_USER,
		UserRoleConst.CABINET_POLL_MANUAL_ENTRY_USER,
	];

	public get token(): string | undefined {
		return this.activeToken;
	}

	public get userRole(): number {
		return this.activRole;
	}

	public get isSuper(): boolean {
		return this.super;
	}

	public get terminalId(): string | undefined {
		return this.storedTerminalId;
	}

	public get isAuthorized(): boolean {
		return !!this.activeToken;
	}

	public get isAuthorized$(): Observable<boolean> {
		return this.authorized$.asObservable();
	}

	constructor(private backendService: BackendService, private notificationService: NotificationService) {
		this.activeToken = this.restoreToken();
		this.super = this.restoreSuper() === "true" || false;
		this.activRole = this.restoreRole();
		this.storedTerminalId = this.restoreTerminalId();
		this.authorized$.next(!!this.activeToken);
	}

	public loginWithToken(token: string, terminalId: string): void {
		this.setToken(token);
		this.setTerminalId(terminalId);
		this.authorized$.next(!!token);
	}

	public login$(credential: AuthenticationParams): Observable<boolean> {
		return this.backendService.auth.login.post$(credential).pipe(
			tap(({body, status}) => {
				if (status === 200 && !body) {
					throw new Error("No token");
				}

				if (body.popUpMessages?.message) {
					if (this.env?.project === "vesta-front") {
						this.notificationService.showNotification("init", body.popUpMessages.message, {withoutDelay: true});
					} else {
						this.notificationService.showNotification("init-wide", body.popUpMessages.message, {withoutDelay: true});
					}
				}

				this.setToken(body.value);
				this.setRole(body.value);
				this.setTerminalId(body.terminalId);
			}),
			tap(({headers}) => {
				this.setSuper(!!parseInt(headers.get("X-AUTH-SUPER") || "", 10));
			}),
			map(({body}) => !!body.value),
			tap((status) => this.authorized$.next(status)),
			take(1)
		);
	}

	public restorePassword$(restoreData: ResetPassword): Observable<any> {
		return this.backendService.auth.restore.post$(restoreData);
	}

	public remindLogin$(remindData: UserRemindLogin): Observable<any> {
		return this.backendService.auth.remind.post$(remindData);
	}

	public clean(): void {
		this.setToken(undefined);
		this.setRole(undefined);
		this.setTerminalId(undefined);
		this.setSuper(undefined, true);
		this.authorized$.next(false);
	}

	public ngOnDestroy(): void {
		this.clean();
		this.authorized$.complete();
	}

	public setToken(token?: string): void {
		this.activeToken = token;
		if (token) {
			sessionStorage.setItem(this.tokenKey, token);
		} else {
			sessionStorage.removeItem(this.tokenKey);
		}
	}

	public setRole(token?: string): void {
		if (token) {
			const roleId = this.roles.find(
				(role) => role.value === JSON.parse(Base64.decode(token.match(/\.(.*)\./)[0].replace(/\./g, ""))).KomplatRole
			).id;
			this.activRole = roleId;
			sessionStorage.setItem(this.roleKey, roleId.toString());
		} else {
			sessionStorage.removeItem(this.roleKey);
		}
	}

	public setTerminalId(terminalId?: string): void {
		this.storedTerminalId = terminalId;
		if (terminalId) {
			sessionStorage.setItem(this.terminalIdKey, terminalId);
		} else {
			sessionStorage.removeItem(this.terminalIdKey);
		}
	}

	public setSuper(isSuper?: boolean, clear?: boolean): void {
		this.super = isSuper;
		if (!clear) {
			sessionStorage.setItem(this.superKey, isSuper?.toString());
		} else {
			sessionStorage.removeItem(this.superKey);
		}
	}

	private restoreToken(): string | undefined {
		return sessionStorage.getItem(this.tokenKey) || undefined;
	}

	private restoreSuper(): string | undefined {
		return sessionStorage.getItem(this.superKey) || undefined;
	}

	private restoreRole(): number | undefined {
		return +sessionStorage.getItem(this.roleKey) || undefined;
	}

	private restoreTerminalId(): string | undefined {
		return sessionStorage.getItem(this.terminalIdKey) || undefined;
	}
}
