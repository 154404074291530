import { Component, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { environment } from "../environments/environment";
import {Router} from "@angular/router";
import {AuthorizationService} from "@core/services/authorization.service";
import {fromEvent} from "rxjs";

@Component({
	selector: "app-root",
	templateUrl: "./app.component.html",
	styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit {
	public env = environment;
	public titleNames = [
		["vesta-front", "Система выставления требований"],
		["nkfo-front", "Кабинет производителя услуг"]
	];
	private events: string[] = ["keydown", "mousemove"];
	private interval: any;
	private timeLogoutInSecond = 900;
	constructor(
		private readonly titleService: Title,
		private router: Router,
		private authorizationService: AuthorizationService
	) { }

	public ngOnInit(): void {
		let title = "";
		if (this.env?.project === "nkfo-front") {
			title = this.titleNames.find(el => el[0] === this.env?.project)[1];
		}
		if (this.env?.project === "vesta-front") {
			title = this.titleNames.find(el => el[0] === this.env?.project)[1];
		}
		this.titleService.setTitle(title);

		this.initSubscribeEventMouse();
	}

	private initSubscribeEventMouse() {
		this.recordLastActive();
		this.events.forEach(event =>
			fromEvent(document, event).subscribe(() => this.recordLastActive())
		);
	}

	private recordLastActive() {
		let cutDown = 0;
		clearInterval(this.interval);
		if (this.authorizationService.isAuthorized) {
			this.interval = setInterval(() => {
				cutDown++;
				if (cutDown > this.timeLogoutInSecond) {
					cutDown = 0;
					clearInterval(this.interval);
					this.router.navigate(["/logout"]);
				}
			}, 1000);
		}
	}
}
