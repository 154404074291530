export interface Claim {
	id: number;
	payerNumber: string;
	invoiceDate: Date | string;
	dueDate: Date | string;
	fullName?: FullName;
	description: string;
	meteringDevices: MeteringDevices;
	sum: Sum;
	paymentSum: number;
	email: string;
	notificationPolitic: NotificationMode;
	phone: string;
	status: ClaimStatus;
	serviceCode: number;
	address?: Address;
	qrCode: string;
	totalSum?: number;
}

export interface Sum {
	quantity?: number;
	price?: number;
	standingSum?: number;
	value: number;
}

export interface Address {
	building?: string;
	buildingPart?: string;
	flat?: string;
	place?: string;
	street?: string;
	value: string;
}

export type ClaimColumnName = "id"
| "payerNumber"
| "invoiceDate"
| "dueDate"
| "fullName"
| "description"
| "sum"
| "paymentSum";

export enum NotificationMode {
	Mute = 0,
	EmailOnly = 1,
	SmsOnly = 2,
	Full = 3
}

export enum ClaimStatus {
	All = 0,
	Paid = 2,
	PartiallyPaid = 3,
	NotPaid = 1,
	NotPaidOnTime = 4
}

export interface FullName {
	surname?: string;
	name?: string;
	patronymic?: string;
}

export interface MeteringDevices {
	count: number;
	lastPaidValues?: DeviceState[];
}

export interface DeviceState {
	idx: number;
	algorithmId?: number;
	deviceName: string;
	unitName: string;
	tariff: number;
	value: number;
	counterWidth?: number;
	prevReg: number;
}

export interface QRCodeParams {
	payerNumber: string;
	claimId: number;
	serviceCode: number;
	shortName: string;
	sum: number;
}
