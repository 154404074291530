import { ChangeDetectionStrategy, Component, Input } from "@angular/core";

@Component({
	selector: "app-svg",
	templateUrl: "./svg.component.html",
	styleUrls: ["./svg.component.scss"],
	changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgComponent {
	@Input() public assetsPath?: string;
	@Input() public svgId = "svg";

	public get path() {
		return `assets/svg-icons/${this.assetsPath}.svg#${this.svgId}`;
	}
}
